@media print {
  .no-print {
    display: none !important;
  }
  .reverse-print {
    flex-direction: column-reverse !important;
  }
  .just-print {
    display: block !important;
  }
  .page-break {
    margin-top: 1rem;
    display: block;
    page-break-after: always;
  }
}

.just-print {
  display: none;
}
